<template>
    <div id="main_app">
        <transition name="slide-fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'MainApp'
    }
</script>
